<template lang="pug">
.module-wrapper.module-page(:class='{ "edit-mode": inEditorMode }')
    LoadingSpinner(v-if='fetchingCurrentModule')
    template(v-else-if='currentModule')
        .header-info
            .module-course-group-cta(v-if='inEditorMode && currentModule.courseGroupId')
                h5.course-group-name(v-html='$t("courses.updateCourseGroup", { courseGroup: courseGroupName })')
                h5.change-course-group(@click='updateCourseGroup') {{ $t('change') }}
            .module-title
                .ordering-number(v-if='isMobileDevice')
                    h6 {{ convertToRoman(inEditorMode ? moduleIndex : moduleIndex + 1) }}
                TextRenderer(
                    :class='{ "title-with-underline": isDesktopDevice }',
                    :edit-sub-title='$t("editor.editModuleTitleSubtext")',
                    :edit-title='$t("editor.title")',
                    :placeholder='$t("editor.newModuleTitle")',
                    :show-text-input='true',
                    :source='currentModule.name || ""',
                    editor-property='name',
                    editor-state='currentModule'
                )
            TextRenderer.module-description(
                :edit-sub-title='$t("editor.editModuleDescriptionSubtext")',
                :edit-title='$t("editor.description")',
                :placeholder='$t("editor.newModuleDescription")',
                :source='currentModule.description || ""',
                editor-property='description',
                editor-state='currentModule'
            )
        VideoOrImageRenderer(
            :editor-property='teaserDataEditorProperty',
            :image-url='currentModule.teaserImageUrl',
            :thumbnail-timestamp='currentModule.thumbnailTimestamp',
            :video-url='currentModule.teaserVideoUrl',
            @image-data='onImageUpload',
            @video-url='onVideoUpload',
            editor-state='currentModule',
            file-type='module_teaser_image',
            track-image-data
        )
        DurationInput.editor(
            :edit-subtext='$t("editor.timestampInputEditSubtext")',
            :edit-title='$t("editor.timestampInputEditTitle")',
            :value='currentModule.thumbnailTimestamp || 0',
            duration-calibration='sec',
            editor-property='thumbnailTimestamp',
            editor-state='currentModule',
            v-if='currentEditorModule && currentEditorModule.teaserVideoUrl'
        )
        TextRenderer.credits(
            :edit-sub-title='$t("editor.teaserCreditsSubtext")',
            :is-small-text='true',
            :placeholder='$t("editor.newTeaserCredits")',
            :show-text-input='true',
            :source='currentModule.credits || ""',
            edit-title='Teaser-Credits',
            editor-property='credits',
            editor-state='currentModule'
        )
        .lesson-tiles-container(v-if='showLessonTiles')
            h3 {{ $t('courses.lessonOverview') }}
            .lesson-tiles
                .tile(
                    :class='{ locked: !isPurchasedCourse && !course.shopifyProductId }',
                    :key='lesson.id',
                    @click='routeToLessonOrShopifyCheckout(lesson)',
                    v-for='lesson in currentModule.lessons'
                )
                    .lesson-number-wrapper(v-if='isDesktopDevice')
                        .lesson-number
                            h5 {{ lesson.orderingNumber + 1 }}
                    h5
                        span(v-if='isMobileDevice') {{ lesson.orderingNumber + 1 + '. ' }}
                        span {{ lesson.title }}
                    SVGRenderer(
                        :has-hover='false',
                        :icon='arrowRightIndicator',
                        stroke-color='var(--primary-foreground-color)',
                        v-if='isMobileDevice',
                        width='20'
                    )
                VPopover(
                    :disabled='!showExpiredCourseTooltip',
                    :open='showExpiredCourseTooltip',
                    :auto-hide='false'
                )
                    .tile.guided-quiz(
                        :class='{ locked: !isPurchasedCourse && !course.shopifyProductId, disabled: course.expired }',
                        @click='routeToLessonOrShopifyCheckout(null, true)',
                        v-if='showGuidedQuizTile',
                        @mouseover='hoveringGuidedQuizCTA = true',
                        @mouseleave='hoveringGuidedQuizCTA = false',
                    )
                        h5 {{ $t('courses.guidedQuizQuestions', { numberOfQuestions: currentModule.guidedQuiz.numberOfQuestions }) }}
                        SVGRenderer(
                            :has-hover='false',
                            :icon='arrowRightIndicator',
                            fill-color='var(--secondary-foreground-color)',
                            v-if='isMobileDevice',
                            width='20'
                        )
                    template(#popover)
                      h6 {{ $t("courses.courseExpiredInfo", { expiredDate: formatDate(courseExpiredAt, 'dd.MM.yyyy'), expiryDuration: courseExpiryDuration }) }}
        .meta-infos(v-if='inEditorMode')
            .text-info
                h5.title Meta-Infos
                h6 {{ $t('editor.metaInfosSubtext') }}
            .configurable-options
                .configurable-option(
                    :key='index',
                    @click='configureModuleOption(option.action)',
                    v-for='(option, index) in configurableModuleOptions'
                )
                    .icon-status
                        SVGRenderer(
                            :has-hover='false',
                            :icon='checkedCheckboxSquare',
                            stroke-color='var(--primary-text-color)',
                            v-if='optionIsChecked(option.action)',
                            width='20'
                        )
                        SVGRenderer(
                            :has-hover='false',
                            :icon='checkboxSquare',
                            stroke-color='var(--primary-text-color)',
                            v-else,
                            width='20'
                        )
                    .text-info
                        h5 {{ option.title }}
                        h6 {{ option.subText }}
        .learnings-wrapper(v-if='isPurchasedCourse || hasPurchasedAtLeastACourse')
            TextRenderer(
                :edit-sub-title='$t("editor.everyNewLine")',
                :edit-title='$t("editor.moduleLearnings")',
                :placeholder='$t("editor.newModuleLearnings")',
                :show-text-area='true',
                :source='currentModuleLearnings',
                editor-property='learnings',
                editor-state='currentModule',
                v-if='inEditorMode'
            )
            template(v-else-if='currentModule.learnings && currentModule.learnings.length')
                h3.title {{ $t('courses.whatYouGet') }}
                .learnings
                    .learning(
                        :key='index',
                        v-for='(learning, index) in currentModule.learnings'
                    )
                        SVGRenderer(
                            :has-hover='false',
                            :icon='checkedCircle',
                            stroke-color='var(--primary-text-color)',
                            width='20'
                        )
                        h5 {{ learning }}
        .benefits-wrapper(v-if='isPurchasedCourse || hasPurchasedAtLeastACourse')
            TextRenderer(
                :edit-sub-title='$t("editor.everyNewLine")',
                :edit-title='$t("editor.yourProfit")',
                :placeholder='$t("editor.newModuleBenefits")',
                :show-text-area='true',
                :source='currentModuleBenefits',
                editor-property='benefits',
                editor-state='currentModule',
                v-if='inEditorMode'
            )
            template(v-else-if='currentModule.benefits && currentModule.benefits.length')
                h3.title {{ $t('courses.yourProfit') }}
                .benefits
                    .benefit(
                        :key='benefit',
                        v-for='benefit in currentModule.benefits'
                    )
                        SVGRenderer(
                            :has-hover='false',
                            :icon='checkedCircle',
                            stroke-color='var(--primary-text-color)',
                            width='20'
                        )
                        h5 {{ benefit }}
        .authors(v-if='currentModule.authors && (isPurchasedCourse || hasPurchasedAtLeastACourse)')
            EditModuleAuthors(
                :authors='currentEditorModuleAuthors',
                v-if='inEditorMode'
            )
            template(v-else-if='currentModule.authors.length')
                h2 {{ $t('courses.expertsInThisModule') }}
                .authors-wrapper
                    .author(
                        :key='author.id',
                        v-for='author in currentModule.authors'
                    )
                        PlaceholderShimmer.author-image(
                            :animate='true',
                            height='130px'
                        )
                            template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
                                img(
                                    :class='{ "shimmer-asset": isLoadingResource }',
                                    :src='author.profileImageUrl',
                                    @load='onResourceLoaded'
                                )
                        h5.name {{ author.name }}
                        h6 {{ author.position }}
        .cta-wrapper(v-if='isDesktopDevice && !inEditorMode')
            .cta.in-module
                .start-module(@click='$emit("start-module")')
                    h5.ketch-font-bold {{ $t('courses.sideshow.startModule') }}
                    SVGRenderer(
                        :has-hover='false',
                        :icon='arrowRight',
                        stroke-color='var(--primary-text-color)'
                    )
</template>

<script lang="ts">
  import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { ConfigurableModuleOption, CourseModule as CourseModuleState, Lesson } from '@/services/interfaces/Course'
  import useIcons from '@/composables/useIcons'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'
  import DurationInput from '@/components/common/DurationInput.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import useEditor from '@/composables/useEditor'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { EditorModule } from '@/store/modules/editor'
  import EditModuleAuthors from '@/components/editor/EditModuleAuthors.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import useShopifyClient from '@/composables/useShopifyClient'
  import { VPopover } from 'v-tooltip'

  export default defineComponent({
    name: 'CourseModule',
    components: {
      EditModuleAuthors,
      SVGRenderer,
      VideoOrImageRenderer,
      TextRenderer,
      DurationInput,
      LoadingSpinner,
      PlaceholderShimmer,
      VPopover,
    },
    metaInfo: function () {
      return {
        title: (this as any).currentModule?.name,
        meta: (this as any).metaDefinitions,
      }
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const { arrowRight, checkedCircle, checkedCheckboxSquare, checkboxSquare, arrowRightIndicator } = useIcons()
      const { course, currentCourseId, moduleId, hasPurchasedAtLeastACourse, isTrialCourse, courseExpiredAt } =
        useCourse()
      const { trackPage, pageViewed } = useSegment()
      const { inEditorMode, currentEditorModule, decodeAndParseSource } = useEditor()
      const { joinArrayOfStringsWithNewlineCharacter, convertToRoman, courseExpiryDuration, formatDate } =
        useCommonMixin()
      const { isMobileDevice, isDesktopDevice } = useBreakpoint()
      const { translateString } = useI18n()
      const { createShopifyCheckout } = useShopifyClient()

      const fetchingCurrentModule = computed(() => {
        if (inEditorMode.value) return currentEditorModule.value === null
        return currentModule.value === null
      })

      const currentModule = computed(() =>
        inEditorMode.value ? EditorModule.currentModule : CourseModule.currentCourseModule,
      )

      const configurableModuleOptions = computed(
        () =>
          [
            {
              title: translateString('editor.moduleIsFreeTitle'),
              subText: translateString('editor.moduleIsFreeSubtext'),
              action: 'isFree',
              checked: currentEditorModule.value?.isFree,
            },
            {
              title: translateString('editor.showLessonTiles'),
              subText: translateString('editor.showLessonTilesSubtext'),
              action: 'hasTiles',
              checked: currentEditorModule.value?.hasTiles,
            },
            {
              title: translateString('editor.showGuidedQuiz'),
              subText: translateString('editor.showGuidedQuizSubtext'),
              action: 'enableGuidedQuiz',
              checked: currentEditorModule.value?.enableGuidedQuiz,
            },
            {
              title: translateString('editor.moduleIsDisabled'),
              subText: translateString('editor.moduleIsDisabledSubtext'),
              action: 'disabled',
              checked: currentEditorModule.value?.disabled,
            },
          ] as ConfigurableModuleOption[],
      )

      const isPurchasedCourse = computed(() => {
        return CourseModule.course?.purchased
      })

      const moduleIndex = computed(() => {
        const courseModules = CourseModule.courseModules(currentCourseId.value, CourseModule.currentCourseGroupId)
        return courseModules.findIndex((m) => m.id === moduleId.value)
      })

      const metaDefinitions = computed(() => {
        return [
          {
            vmid: 'og:description',
            name: 'og:description',
            property: 'og:description',
            content: `KetchUp: ${decodeAndParseSource(currentModule.value?.description ?? '')}`,
          },
        ]
      })

      const currentModuleLearnings = computed(() => {
        if (!currentModule.value?.learnings) return ''
        if (!currentModule.value.learnings.length) return ''
        return joinArrayOfStringsWithNewlineCharacter(currentModule.value.learnings)
      })

      const currentModuleBenefits = computed(() => {
        if (!currentModule.value?.benefits) return ''
        if (!currentModule.value.benefits.length) return ''
        return joinArrayOfStringsWithNewlineCharacter(currentModule.value.benefits)
      })

      const moduleOptionClicked = computed(() => {
        return (action: string) => {
          return configurableModuleOptions.value.find((option) => {
            return option.action === action
          })
        }
      })

      const optionIsChecked = computed(() => {
        return (action: string) => {
          const optionClicked = moduleOptionClicked.value(action)
          return optionClicked?.checked
        }
      })

      const currentEditorModuleAuthors = computed(() => {
        return EditorModule.currentModuleAuthors
      })

      const courseGroupName = computed(
        () => course.value?.courseGroups.find((cG) => cG.id === currentModule.value?.courseGroupId)?.name,
      )

      const teaserDataEditorProperty = computed(() => {
        if (currentModule.value?.teaserImageUrl || uploadedModuleTeaserImage.value) return 'teaserImageUrl'
        return currentModule.value?.teaserVideoUrl ? 'teaserVideoUrl' : undefined
      })

      const showLessonTiles = computed(
        () =>
          currentModule.value?.hasTiles &&
          !inEditorMode.value &&
          currentModule.value?.lessons &&
          currentModule.value?.lessons.length,
      )

      const showGuidedQuizTile = computed(
        () => currentModule.value?.guidedQuiz && currentModule.value?.guidedQuiz.numberOfQuestions > 0,
      )

      watch(moduleId, () => {
        trackPage('Course Module View')
        pageViewed('Course Module View')
      })

      const configureModuleOption = (action: 'isFree' | 'hasTiles' | 'enableGuidedQuiz' | 'disabled') => {
        const optionClicked = moduleOptionClicked.value(action)
        if (optionClicked) {
          optionClicked.checked = !optionClicked.checked
          EditorModule.setEditableState({
            key: 'currentModule' + action,
            path: route.path,
            state: 'currentModule',
            property: action as keyof (Lesson | CourseModuleState),
            value: optionClicked.checked,
          })
        }
      }

      const routeToLessonOrShopifyCheckout = async (lesson: Lesson | null, isGuidedQuiz?: boolean) => {
        if (!currentModule.value?.isFree && !course.value?.purchased && !course.value?.expired) {
          await createShopifyCheckout()
        } else {
          if (isGuidedQuiz) {
            const expired = course.value?.expired
            if (expired) return
            await router.push(`/courses/${course.value?.id}/module/${currentModule.value?.id}/guided-quiz`)
          } else {
            await router.push(`/courses/${course.value?.id}/module/${currentModule.value?.id}/lesson/${lesson!.id}`)
          }
        }
      }

      const onVideoUpload = (url: string) => {
        uploadedModuleTeaserImage.value = false
        EditorModule.setTeaserVideoUrl(url)
      }

      const uploadedModuleTeaserImage = ref(false)
      const onImageUpload = () => {
        uploadedModuleTeaserImage.value = true
      }

      const updateCourseGroup = () => {
        eventBus.$emit('show-modal', {
          modalContentComponent: 'CreateOrUpdateCourseGroupModal',
          modalProps: {
            courseId: currentCourseId.value,
            updateCourseGroup: true,
          },
          cssClass: 'create-course-group-modal',
          modalCloseCallback: (callback: () => void) => {
            if (typeof callback === 'function') callback()
          },
        })
      }

      const hoveringGuidedQuizCTA = ref(false)
      const showExpiredCourseTooltip = ref(false)

      watch(hoveringGuidedQuizCTA, (value) => {
        showExpiredCourseTooltip.value = value && course.value!.expired
      })

      onMounted(() => {
        trackPage('Course Module View')
        pageViewed('Course Module View')
      })

      onBeforeUnmount(() => {
        if (inEditorMode.value) {
          EditorModule.resetEditorChanges()
        }
      })

      return {
        currentModule,
        course,
        isTrialCourse,
        arrowRight,
        checkedCircle,
        checkboxSquare,
        checkedCheckboxSquare,
        currentCourseId,
        inEditorMode,
        hasPurchasedAtLeastACourse,
        fetchingCurrentModule,
        currentEditorModule,
        configurableModuleOptions,
        isPurchasedCourse,
        metaDefinitions,
        currentModuleLearnings,
        currentModuleBenefits,
        optionIsChecked,
        currentEditorModuleAuthors,
        arrowRightIndicator,
        isMobileDevice,
        isDesktopDevice,
        moduleIndex,
        courseGroupName,
        teaserDataEditorProperty,
        showLessonTiles,
        showGuidedQuizTile,
        hoveringGuidedQuizCTA,
        showExpiredCourseTooltip,
        courseExpiredAt,
        courseExpiryDuration,
        convertToRoman,
        formatDate,
        configureModuleOption,
        onVideoUpload,
        onImageUpload,
        routeToLessonOrShopifyCheckout,
        updateCourseGroup,
      }
    },
  })
</script>

<style lang="postcss">
  .module-wrapper.module-page {
    @apply ketch-pb-c40;
    &.edit-mode {
      .header-info {
        @apply ketch-max-w-full ketch-space-y-c20 ketch-mb-c30;
      }
      .credits {
        @apply ketch-my-c30;
        h6 {
          @apply ketch-opacity-100;
        }
      }
    }
    .header-info {
      @apply md:ketch-max-w-[550px] ketch-my-c15;
      .module-course-group-cta {
        @apply ketch-bg-editor-primary-color ketch-bg-opacity-5 ketch-py-c10 ketch-text-center ketch-rounded-normal;
        h5.course-group-name {
          span {
            @apply ketch-text-editor-primary-color ketch-font-bold;
          }
        }
        h5.change-course-group {
          @apply ketch-underline ketch-font-bold ketch-cursor-pointer;
        }
      }
      .module-title {
        @apply ketch-flex ketch-items-center ketch-space-x-c10;
        .text-renderer {
          @apply ketch-flex-1;
        }
        .ordering-number {
          flex: 0 0 24px;
          @apply ketch-w-[24px] ketch-h-[24px] ketch-border ketch-border-border-color ketch-rounded-full ketch-text-white;
          @apply ketch-flex ketch-items-center ketch-justify-center ketch-bg-gray-700 ketch-border-gray-700;
        }
        .title-with-underline {
          @apply ketch-pb-c10;
        }
        h2 {
          @apply ketch-font-bold;
        }
      }
    }
    .teaser-image {
      @apply ketch-mb-c30 md:ketch-mb-0;
      img {
        @apply ketch-w-full;
      }
    }
    .credits {
      @apply ketch-mt-c10 ketch-mb-c20;
      h6 {
        @apply ketch-opacity-50;
      }
    }
    .duration-input,
    .outro-text {
      @apply ketch-mt-c30;
    }
    .lesson-tiles-container {
      @apply ketch-my-c40;
      h3 {
        @apply ketch-font-bold ketch-mb-c10;
      }
      .lesson-tiles {
        @apply ketch-flex ketch-flex-col md:ketch-grid md:ketch-flex-wrap ketch-gap-c10;
        grid-template-columns: repeat(auto-fill, 205px);
        .tile {
          @apply ketch-rounded-[5px] md:ketch-rounded-none md:ketch-w-[205px] md:ketch-h-c100 ketch-bg-primary-color;
          @apply ketch-text-primary-foreground-color ketch-flex ketch-justify-between ketch-items-center;
          @apply ketch-px-c15 ketch-py-c10 ketch-cursor-pointer ketch-border ketch-border-primary-color;
          @apply md:ketch-items-start md:ketch-p-c10 md:ketch-flex-col;
          h5 {
            @apply ketch-whitespace-nowrap md:ketch-whitespace-pre-wrap ketch-text-ellipsis ketch-overflow-hidden;
            @apply ketch-pr-c10 md:ketch-pr-0;
          }
          @screen md {
            word-break: break-word;
            h5 {
              @apply ketch-overflow-hidden ketch-font-bold;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
          }
          svg {
            @apply ketch-opacity-[0.7];
          }
          &:hover {
            box-shadow: var(--tile-primary-shadow);
          }
          &.guided-quiz {
            @apply ketch-bg-secondary-color ketch-text-secondary-foreground-color ketch-space-x-c8 ketch-border-secondary-color;
            &:hover {
              box-shadow: var(--tile-secondary-shadow);
            }
            &.disabled {
              @apply ketch-opacity-50;
            }
          }
          .lesson-number-wrapper {
            @apply ketch-w-full ketch-flex ketch-justify-end;
            .lesson-number {
              @apply ketch-h-c25 ketch-w-c30 ketch-flex ketch-items-center ketch-justify-center;
              @apply ketch--mt-c10 ketch--mr-c10 ketch-bg-primary-foreground-color ketch-text-primary-color;
            }
          }
        }
      }
    }
    .learnings-wrapper,
    .benefits-wrapper {
      @apply ketch-flex ketch-flex-col ketch-mt-c20;
      .title {
        @apply ketch-mb-c15 ketch-font-bold;
      }
      .learnings,
      .benefits {
        @apply ketch-space-y-c10;
        .learning,
        .benefit {
          @apply ketch-flex ketch-items-center ketch-space-x-c10;
          svg {
            flex: 0 0 20px;
          }
        }
      }
    }
    .learnings-wrapper,
    .benefits-wrapper {
      @apply ketch-mb-c20;
    }
  }
</style>
