<script setup lang="ts">
  import useCommonMixin from '@/composables/useCommonMixin'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import { computed, onMounted, ref } from 'vue'
  import UsersApi from '@/services/api/UsersApi'
  import { useRoute, useRouter } from 'vue-router/composables'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import { useVuelidate } from '@vuelidate/core'
  import type { ValidationArgs } from '@vuelidate/core'
  import { required, email } from '@vuelidate/validators'
  import CommonApi from '@/services/api/CommonApi'

  const route = useRoute()
  const router = useRouter()

  const form = ref({
    email: '',
  } as { [key: string]: string })
  const confirmationToken = ref('')
  const settingUpAccount = ref(false)

  const validation: ValidationArgs<any> = {
    email: {
      required,
      email,
    },
  }

  const $v: any = useVuelidate(validation, form.value)

  const emailErrors = computed((): string[] => {
    const errors: string[] = []
    if (!$v.value.email.$dirty) return errors
    if ($v.value.email.required.$invalid) errors.push(translateString('onboarding.errors.requiredField'))
    else if ($v.value.email.email.$invalid) errors.push(translateString('onboarding.errors.invalidEmail'))

    return errors
  })

  const pendingOrderId = computed(() => route.params.pendingOrderId)

  const { setIsLoadingComponentViewData, desktopLogo } = useCommonMixin()
  const { translateString } = useI18n()

  const routeToHomepage = () => router.push('/')

  const setPrimaryEmailAndCompleteOrder = () => {
    if (!pendingOrderId.value && !form.value.email && !confirmationToken.value) return
    settingUpAccount.value = true
    UsersApi.completePendingOrder(pendingOrderId.value, form.value.email, confirmationToken.value)
      .then(() => {
        settingUpAccount.value = false
        eventBus.$toasted.success(translateString('onboarding.accountCreated'))

        setTimeout(() => {
          window.location.href = '/'
        }, 1500)
      })
      .catch((error) => {
        if (error.response && typeof error.response.status === 'number') {
          CommonApi.postSlackMessage(
            `Could not complete order.\nPending Order: ${pendingOrderId.value}\nResponse: ${error.response.data}`,
          )
        }
      })
  }

  const fetchPendingOrder = () => {
    if (!pendingOrderId.value) return
    UsersApi.getPendingOrder(pendingOrderId.value)
      .then((resp) => {
        if (!resp.active) {
          routeToHomepage()
          return
        }
        form.value.email = resp.email
        confirmationToken.value = resp.confirmationToken
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          routeToHomepage()
        }
      })
  }

  setIsLoadingComponentViewData(false)
  fetchPendingOrder()

  onMounted(() => {
    if (!pendingOrderId.value) routeToHomepage()
  })
</script>

<template lang="pug">
.set-primary-email-wrapper
  .template(v-if='desktopLogo')
    img(
      :src='desktopLogo',
      alt='logo'
    )
  .set-primary-email-header-text
    h2 {{ $t('onboarding.setAccount') }}
    h3 {{ $t('onboarding.chooseEmail') }}
  .set-primary-email-container
    .set-primary-email-form
      label.h3(for='email') Email
      input.h3(
        v-model='form.email',
        type='email',
        required,
        autofocus,
        placeholder='name@email.de',
        :class='{ "has-error": emailErrors[0] }',
        @blur='$v.email.$touch()',
        @input='$v.email.$touch()',
      )
      h5.error(v-if='emailErrors.length') {{ emailErrors[0] }}
      KetchUpButton(
        @click.native='setPrimaryEmailAndCompleteOrder',
        :disabled='!form.email || emailErrors.length || settingUpAccount'
      )
        LoadingSpinner(v-if='settingUpAccount')
        h5(v-else) {{ $t('letsGo') }}
</template>

<style scoped lang="postcss">
  .set-primary-email-wrapper {
    @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-space-y-c25 ketch-text-[#00254A];
    .template {
      img {
        @apply ketch-max-h-c45;
      }
    }
    .set-primary-email-header-text {
      @apply ketch-text-center;
      h2 {
        @apply ketch-font-bold;
      }
    }
    .set-primary-email-container {
      @apply ketch-p-c20 ketch-rounded-lg ketch-bg-white ketch-w-full sm:ketch-w-[480px];
      .set-primary-email-form {
        @apply ketch-flex ketch-flex-col;
        label {
          @apply ketch-font-bold ketch-mb-c5;
        }
        input {
          @apply ketch-border ketch-px-c15 ketch-py-c10 ketch-rounded-small ketch-w-full;
          @apply ketch-outline-none focus:ketch-border-[#008A40];
          &.has-error {
            @apply ketch-border-red-500;
          }
        }
        .error {
          @apply ketch-text-red-500;
        }
        button {
          @apply ketch-w-full ketch-mt-c20 ketch-rounded-xlarge ketch-bg-[#008A40] ketch-border-[#008A40];
          h5 {
            @apply ketch-text-white;
          }
        }
      }
    }
  }
</style>
